const isBrowser = typeof window !== 'undefined'

module.exports = {
  i18n: {
    locales: ["fr", "es"],
    defaultLocale: "fr",
    localeDetection: false,
    domains:
      process.env.PLASMIC_ENV === "production"
        ? [
            {
              domain: "benebono.fr",
              defaultLocale: "fr",
            },
            {
              domain: "benebono.es",
              defaultLocale: "es",
            },
          ]
        : [
            {
              domain: "next-staging.benebono.es",
              defaultLocale: "es",
            },
            {
              domain: "next-staging.benebono.fr",
              defaultLocale: "fr",
            },
            //domains value must follow format { domain: 'example.fr', defaultLocale: 'fr', locales: ['fr'] }.
          ],
  },
  serializeConfig: false,
  partialBundledLanguages: isBrowser && true,
};
