import { appWithTranslation } from "next-i18next";
import "../styles/index.scss";
import dayjs from "dayjs";
import "dayjs/locale/fr";
//we might be able to import only the necessary one
import "dayjs/locale/es";
import { useRouter } from "next/router";
import nextI18nextConfig from "../next-i18next.config";
import { useHydrateAtoms } from "jotai/utils";
import { localeAtom } from "../utils/helpers/store";

function MyApp({ Component, pageProps }) {
	//get the local from pageProps
	const { locale } = pageProps;
	const pageLocale = locale ? locale : "fr-FR";
	//set it as a state
	useHydrateAtoms([[localeAtom, pageLocale]]);

	dayjs.locale(pageLocale.substring(0, 2));
	const router = useRouter();
	const { pathname } = router;
	const inEditor = Component.isPlasmicHost;
	const pageWithoutFooter = ["/storelocator", "/checkout"];

	return <Component {...pageProps} />;
}

export default appWithTranslation(MyApp, nextI18nextConfig);
